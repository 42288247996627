import { getCookie } from "../utils/globals";

export default function setup(){
	initialiseCookiesBar();

	function initialiseCookiesBar() {
		const COOKIE_NAME = "cofense.cookielevelselection";
		const COOKIE_LEVEL_NAME = "cofense.cookieconsentlevel";
		
		const cookieBar = document.querySelector(".js-cookie-banner");

		const cookieValue = getCookie(COOKIE_NAME);
		const cookieLevelValue = getCookie(COOKIE_LEVEL_NAME);
		
		if (!cookieBar || cookieValue === "true" || cookieLevelValue === "4") {
			return;
		}

		const cookieBarCtas = document.querySelectorAll(".js-cookie-banner__close");

		cookieBarCtas.forEach(function (elem) {
			elem.addEventListener("click", () => {
				cookieBar.classList.remove("js-cookie-banner__is-active");
			});
		});
	}
	
}

